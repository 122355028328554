body {
  background-color: #ACC2EA;
  padding: 20px;
  margin: 0;
}
h1, h2, p, ul, li {
  font-family: sans-serif;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #999999;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: #FFF;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}

.active {
  background-color: #77C8FF;
}

li {
  margin: 1px 1px 1px 1px;
}

h1.resume-header {
  margin-top: 0px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}